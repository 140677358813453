import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// date function imports
import { getRelativeTimestringUtc } from "../../utils/dateUtils";

import { Stack } from "@mui/material";
import { formatISO } from "date-fns";
import { listGroupEvents } from "../../actions/eventActions";
import { GROUP_EVENT_LIST_RESET } from "../../constants/eventConstants";
import { SAAS_EVENT_SUMMARY_FEATURE_TOGGLE } from "../../constants/featureFlagConstants";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { groupBy } from "../../utils/arrayHelper";
import { isEnabled } from "../../utils/featureFlags";
import { isEmpty } from "../../utils/is";
import { DaySeparator, Loading } from "../common/styled-components";
import { Message } from "../common/ui-components/Message";
import Group from "./Group";
import NoData from "./components/NoEvents";
import { DEFAULT_TIMEZONE } from "../../constants/commonConstants";
import moment from "moment-timezone";

function EventGroupsFeed() {
  const dispatch = useDispatch();

  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;

  // Holds the element ref for the div that contains the records and needs to be re-rendered with new records
  const container = useRef(null);
  const [hasEnded, setHasEnded] = useState(false); // to indicate whether or not we've fetched all the records
  const [page, setPage] = useState(1);
  const { data: groupEventList, loading: groupLoading } = useSelector(
    (state) => state.groupEventsList
  );
  const selectedTenant = useSelectedTenant();

  const tenantGlobalFilter = useSelector(
    (state) => state.tenantGlobalFilter?.appliedFilter,
    (currentFilter, previousFilter) => {
      if (currentFilter !== previousFilter) {
        // reset the full list on global filter change and start a fresh
        dispatch({
          type: GROUP_EVENT_LIST_RESET,
          payload: [],
        });
        setHasEnded(false);
        setPage(1);
      }
    }
  );

  const [fetchEventGroups, setFetchEventGroups] = useState(true);

  const eventsTimeFilter = useSelector(
    (state) => state.eventsFeedFilters?.timestamps,
    (currentFilter, previousFilter) => {
      if (JSON.stringify(currentFilter) !== JSON.stringify(previousFilter)) {
        // reset the full list on global filter change and start a fresh
        dispatch({
          type: GROUP_EVENT_LIST_RESET,
          payload: [],
        });
        setHasEnded(false);
        setPage(1);
      }
    }
  );

  const eventsSummaryFilter = useSelector(
    (state) => state.eventsFeedFilters?.summaryFilters,
    (currentFilter, previousFilter) => {
      if (JSON.stringify(currentFilter) !== JSON.stringify(previousFilter)) {
        // reset the full list on global filter change and start a fresh
        dispatch({
          type: GROUP_EVENT_LIST_RESET,
          payload: [],
        });
        setHasEnded(false);
        setPage(1);
      }
    }
  );

  // This side effect is used to track if there are more pages to track;
  // It also cleans up the event listener each time the scroll event occurs
  useEffect(() => {
    if (!hasEnded) {
      fetchGroup();

      if (!fetchEventGroups) setFetchEventGroups(true);
    }

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, [page, tenantGlobalFilter, eventsTimeFilter, eventsSummaryFilter]);

  // This side effect adds an event listener, each time a scroll event occurs
  useEffect(() => {
    if (groupEventList && groupEventList.length) {
      document.addEventListener("scroll", trackScrolling);
    }
  }, [groupEventList]);

  // This function keeps track of scrolling; it will allow scrolling until
  // it has reached the bottom of the screen
  const trackScrolling = () => {
    if (
      container.current &&
      parseInt(container.current.getBoundingClientRect().bottom) <= window.innerHeight &&
      fetchEventGroups
    ) {
      setPage(page + 1);
      document.removeEventListener("scroll", trackScrolling);
    }
  };

  // conditionally populate query params
  // Only populate the filter params when the feature toggles are enabled
  const prepareParams = ()=>{
    let queryParams = {
      tenant_id: selectedTenant,
      page_num: page,
      dim_name: tenantGlobalFilter?.dimension_name,
      dim_val: tenantGlobalFilter?.dimension_value,
    };
    if(isEnabled(SAAS_EVENT_SUMMARY_FEATURE_TOGGLE)){
      queryParams.start_time =formatISO(eventsTimeFilter?.startDate, { representation: "date" });
      queryParams.end_time = formatISO(eventsTimeFilter?.endDate, { representation: "date" });
      // Below lines of code handles all the filters present in summary cards.
      if (!isEmpty(eventsSummaryFilter)) {
        Object.entries(eventsSummaryFilter).map(([filterName, filterValue]) => {
          if (filterValue) {
            // In case of provider filter we have to store an object so that's why we are checking specifically for filtername.
            if (filterName === 'provider_uuid') {
              queryParams[filterName] = filterValue.id;
            } else if (filterName === 'status') {
              // We have to show status as live but backend requires active so below change.
              queryParams[filterName] = filterValue === 'Live' ? 'Active' : filterValue;
            } else if (filterName === 'eventType') {
              // We are storing object for event type which has type and sub type so below code.
              queryParams.type = filterValue.type;
              if (filterValue?.subType) {
                queryParams.sub_type = filterValue.subType;
              }
            } else {
              queryParams[filterName] = filterValue;
            }
          }
        });
      }
    }

    return queryParams;
  };

  const fetchGroup = () => {
    dispatch(
      listGroupEvents(prepareParams())
    ).then((result) => {
      if (!result?.event_groups?.length) {
        setHasEnded(true);
      }
    });
  };

  const renderGroupEvents = () => {
    const newGroupList = groupEventList.map((record) => {
      const formattedStartDate = moment(record.event_group_ts_start).tz(timezone).format();
      return ({
      ...record,
      insertion_date: formattedStartDate.substr(
        0,
        formattedStartDate.indexOf("T")
      ),
    });
  });
    const grouped = groupBy(newGroupList, "insertion_date");

    if (hasEnded && groupEventList && groupEventList.length === 0) {
      return (
        <Box>
          <NoData pageSource="events" isFilterSelected={isEnabled(SAAS_EVENT_SUMMARY_FEATURE_TOGGLE) && !isEmpty(eventsSummaryFilter)}/>
        </Box>
      );
    }
    return (
      <Timeline
        sx={(theme) => ({
          marginBlock: 0, // Combined margin-block
          paddingInlineStart: 0,
          minHeight: "auto",
          padding: 0,
        })}
      >
        {grouped &&
          Object.keys(grouped).map((key, index) => (
            <TimelineItem
              key={index}
              sx={(theme) => ({
                "&::before": {
                  content: "none", // Disable the before pseudo-element
                  padding: 0, // Remove padding
                  flex: 0, // Disable flex behavior
                },
              })}
            >
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: "var(--greyish-lavender)" }} />
                <TimelineConnector
                  sx={{
                    backgroundColor: "unset",
                    borderLeft: "1px dashed #84818A",
                    opacity: 0.6,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent
                style={{ marginBottom: 10, gap: 3, padding: "3px 0 3px 16px" }}
                sx={(theme) => ({
                  [theme.breakpoints.down("tm")]: {
                    width: "100%",
                  },
                })}
              >
                <DaySeparator
                  style={{
                    height: "unset",
                    fontSize: "14px",
                    color: "var(--charcoal-grey)",
                    fontWeight: 400,
                    paddingTop: 0,
                  }}
                  data-snippyly-comment-disabled
                >
                  {getRelativeTimestringUtc(key, new Date().toISOString().split("T")[0])}
                </DaySeparator>
                <Stack direction="column" gap={6} mt={5}>
                  {grouped[key].map((groupedEvent, index) => (
                    <div key={groupedEvent.event_group_uuid} style={{ position: "relative" }}>
                      <Group
                        groupId={groupedEvent.event_group_uuid}
                        insertionTs={groupedEvent.event_group_ts_start}
                      />
                    </div>
                  ))}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    );
  };

  if (!groupEventList) return <div />;

  return (
    <Box
      ref={container}
      sx={(theme) => ({
        padding: "48px 150px",
        paddingTop: "0px",
        marginTop: "var(--events-feed-summary-height)",
        [theme.breakpoints.down("tm")]: {
          marginTop: "20px",
          padding: "0px 20px 48px",
        },
      })}
    >
      <Box sx={{ mt: 7.5 }}>{renderGroupEvents()}</Box>
      {groupLoading && <Loading data-testid="loader" />}
      {!groupLoading && hasEnded && groupEventList?.length !== 0 && (
        <Message>{"You're all caught up here!"}</Message>
      )}
    </Box>
  );
}

export default EventGroupsFeed;
