import styled from "@emotion/styled";
import LowPriorityRoundedIcon from "@mui/icons-material/LowPriorityRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { formatISO, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  PIPELINE_SCHEDULE_TRANSLATION
} from "../../../constants/commonConstants";
import { EVENT_SUB_TYPE_ASSET, EVENT_SUB_TYPE_BEACON, EVENT_SUB_TYPE_CDP, EVENT_SUB_TYPE_EXPERIRENCE, EVENT_SUB_TYPE_JAVASCRIPT, EVENT_SUB_TYPE_NETWORK, EVENT_SUB_TYPE_OUTAGE, EVENTS_FEED_CLEAR_FILTERS, EVENTS_FEED_SUMMARY_FILTERS } from "../../../constants/eventConstants";
import { useAxios } from "../../../hooks/useAxios";
import { useSelectedTenant } from "../../../hooks/useSelectedTenant";
import { colors, muiTheme, typography } from "../../../shared/theme-constants";
import { generateDifferenceInTimeString } from "../../../utils/dateUtils";
import { isEmpty } from "../../../utils/is";
import Icon from "../../common/Icon";
import MultiSelect from "../../common/mui-wrapper-components/Select/MultiSelect";
import { StyledInput } from "../../common/styled-components/collaborate/MultiSelect.styled";
import EventFeedTimeFilter from "./EventFeedTimeFilter";
import EventSeverityLabels from "./EventSeverityLabels";
import EventSummaryCard from "./EventSummaryCard";

const StyledChip = styled(Chip)(({ theme, padding }) => ({
  minWidth: theme.typography.pxToRem(70),
  height: theme.typography.pxToRem(30),
  borderRadius: theme.typography.pxToRem(6),
  "&& .MuiChip-label": {
    paddingLeft: padding ? `${padding} !important` : "0rem !important",
    paddingRight: padding ? `${padding} !important` : "0rem !important",
    fontFamily: "Poppins !important",
    fontSize: "11px",
    fontWeight: 400,
  },
  "&& .MuiChip-icon": {
    margin: 0,
    marginRight: "4px",
  },
}));

const severityOptions = [
  {
    label: "High",
    value: "High",
    icon: `${process.env.REACT_APP_CDN_BASE_URL}/images/high_severity.png`,
  },
  {
    label: "Medium",
    value: "Medium",
    icon: `${process.env.REACT_APP_CDN_BASE_URL}/images/medium_severity.png`,
  },
  {
    label: "Low",
    value: "Low",
    icon: `${process.env.REACT_APP_CDN_BASE_URL}/images/low_severity.png`,
  },
];

const statusOptions = [
  {
    label: "Closed",
    value: "Closed",
    icon: `${process.env.REACT_APP_CDN_BASE_URL}/images/event_closed.png`,
  },
  {
    label: "Live",
    value: "Live",
    icon: `${process.env.REACT_APP_CDN_BASE_URL}/images/event_live.png`,
  },
];

const typeOptions = [
  {
    label: "Outages",
    value: { type: "outage", subType: EVENT_SUB_TYPE_OUTAGE },
  },
  {
    label: "CDP",
    value: { type: "error", subType: EVENT_SUB_TYPE_CDP },
  },
  {
    label: "Javascript",
    value: { type: "error", subType: EVENT_SUB_TYPE_JAVASCRIPT },
  },
  {
    label: "Beacon",
    value: { type: "error", subType: EVENT_SUB_TYPE_BEACON },
  },
  {
    label: "Network",
    value: { type: "error", subType: EVENT_SUB_TYPE_NETWORK },
  },
  {
    label: "Experience",
    value: { type: "error", subType: EVENT_SUB_TYPE_EXPERIRENCE },
  },
  {
    label: "Fetch",
    value: { type: "error", subType: EVENT_SUB_TYPE_ASSET }
  }
];

const EventSummary = ({ startTime, endTime, pipelineSchedule, pageType, isRestructured = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const selectedTenant = useSelectedTenant();
  const summaryFilters = useSelector((state) => state.eventsFeedFilters.summaryFilters);
  const [providerOptions, setProviderOptions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = {
    dim_name: tenantGlobalFilter?.dimension_name,
    dim_val: tenantGlobalFilter?.dimension_value,
    tenant_id: selectedTenant,
    start_time: formatISO(startTime, { representation: "date" }),
    end_time: formatISO(endTime, { representation: "date" }),
    frequency: pipelineSchedule,
    ...(summaryFilters?.severity && { severity: summaryFilters.severity }),
    ...(summaryFilters?.status && { status: summaryFilters.status }),
    ...(summaryFilters?.provider_uuid?.id && { provider_uuid: summaryFilters.provider_uuid.id }),
    ...(summaryFilters?.eventType?.type && { type: summaryFilters.eventType.type }),
    ...(summaryFilters?.eventType?.subType && { subType: summaryFilters.eventType.subType }),
  };

  const {
    data: eventsSummaryData,
    status: status,
    isLoading: loading,
  } = useAxios("events/summary", {
    params: params,
  });

  const severityPercentChange =
    eventsSummaryData?.data?.severity?.change_from_last_month?.percentage;

  useEffect(() => {
    if (eventsSummaryData) {
      const providersList = eventsSummaryData.data.top_providers.map((item) => ({
        value: item.provider_uuid,
        label: item.provider_display_name,
        logo: item.provider_logo_url,
      }));
      setProviderOptions(providersList);
    }
  }, [eventsSummaryData]);

  const isMobileScreen = useMediaQuery(muiTheme.breakpoints.down("tm"));

  const handleDigestNavigation = (filterType = null, filterValue = null) => {
    const updatedFilters = { ...summaryFilters };
    if (filterType) {
      updatedFilters[filterType] = filterValue;
    }
    const updatedParams = new URLSearchParams();
    // Convert the summaryFilters object to query parameters
    for (const [key, value] of Object.entries(updatedFilters)) {
      if (key === "provider_uuid") {
        updatedParams.set(key, encodeURIComponent(JSON.stringify(value)));
      } else {
        updatedParams.set(key, value);
      }
    }
    dispatch({
      type: EVENTS_FEED_SUMMARY_FILTERS,
      payload: { value: filterValue, type: filterType },
    });
    navigate(`/events?${updatedParams.toString()}`);
  };

  // On selection of any filter in summary cards, below function will dispatch  call with filter name and its value and it will be stored accordingly.
  const handleSummaryFilters = (event, filterType, filterValue) => {
    event.stopPropagation();
    if (pageType === "digest") {
      return handleDigestNavigation(filterType, filterValue);
    }
    // Create a new URLSearchParams object from the current search parameters
    const updatedParams = new URLSearchParams(searchParams);
    if (filterValue === null) {
      // Remove the filter from the query parameters if the value is null
      updatedParams.delete(filterType);
    } else {
      // Otherwise, set or update the filter with its value
      if (filterType === "provider_uuid") {
        updatedParams.set("provider_uuid", JSON.stringify(filterValue));
      } else if (filterType === "eventType") {
        updatedParams.set("eventType", JSON.stringify(filterValue));
      } else {
        updatedParams.set(filterType, filterValue);
      }
    }
    // Update the URL with the modified search parameters
    setSearchParams(updatedParams);
    dispatch({
      type: EVENTS_FEED_SUMMARY_FILTERS,
      payload: { value: filterValue, type: filterType },
    });
  };

  const handleClearFilters = () => {
    dispatch({
      type: EVENTS_FEED_CLEAR_FILTERS,
    });
  };

  const showLastProcessedData = () => {
    return (
      <Stack direction="row" gap={2} sx={isRestructured ? { display: "none" } : {}}>
      <Stack direction="row" gap={1}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            color: "rgba(61, 61, 61, 1)",
            fontWeight: typography.font_weight.light,
            [theme.breakpoints.down("st")]: {
              fontSize: theme.typography.pxToRem(11),
            },
          })}
        >
          Last Processed :
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            color: "rgba(61, 61, 61, 1)",
            fontWeight: typography.font_weight.mediumx,
            [theme.breakpoints.down("st")]: {
              fontSize: theme.typography.pxToRem(11),
            },
          })}
        >
          {eventsSummaryData?.data?.last_event_ts
            ? generateDifferenceInTimeString(parseISO(eventsSummaryData?.data?.last_event_ts))
            : "-"}
        </Typography>
      </Stack>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "var(--black)", height: "13px", alignSelf: "center" }}
      />
      <Stack direction="row" gap={1}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            color: "rgba(61, 61, 61, 1)",
            fontWeight: typography.font_weight.light,
            [theme.breakpoints.down("st")]: {
              fontSize: theme.typography.pxToRem(11),
            },
          })}
        >
          Total Errors Received :
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(12),
            color: "rgba(61, 61, 61, 1)",
            fontWeight: typography.font_weight.mediumx,
            [theme.breakpoints.down("st")]: {
              fontSize: theme.typography.pxToRem(11),
            },
          })}
        >
          {eventsSummaryData?.data?.total_events?.formatted_count}
        </Typography>
      </Stack>
    </Stack>
    );
  };

  return (
    <>
      <EventSummaryCard
        sx={(theme) => ({
          width: "100%",
          position: isMobileScreen ? "relative" : "fixed",
          zIndex: location?.pathname === "/events" ? 1000 : "unset",
          padding: pageType === "digest" ? 0 : "20px 150px",
          [theme.breakpoints.down("tm")]: {
            padding: "20px",
          },
        })}
      >
        <Stack direction="column" gap={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={(theme) => ({
              [theme.breakpoints.down("tm")]: {
                flexDirection: "column",
                gap: "12px",
              },
            })}
          >
            <Stack
              direction={isRestructured ? "row" : "column"}
              gap={4}
            >
              <Stack
                direction="column"
                gap={1}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(isRestructured ? 20 : 30),
                    color: "var(--charcoal-grey)",
                    fontWeight: typography.font_weight.semiBold,
                    [theme.breakpoints.down("tm")]: {
                      fontSize: theme.typography.pxToRem(isRestructured ? 16 : 24),
                    },
                  })}
                >
                  Event Summary
                </Typography>
                {showLastProcessedData()}
              </Stack>
              <Stack direction="column" gap={2}>
                {/* Commenting out below code. Once we get feedback then will remove it. */}
                {/* <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    color: "var(--greyish-lavender)",
                    fontWeight: typography.font_weight.mediumx,
                  })}
                >
                  Events Impacting Business
                </Typography> */}
                <Stack direction="row" gap={2}>
                <EventSeverityLabels label={`High ${eventsSummaryData?.data?.severity?.High?.count || '-'}`} severity="High" />
                <EventSeverityLabels label={`Medium ${eventsSummaryData?.data?.severity?.Medium?.count || '-'}`} severity="Medium" />
                <EventSeverityLabels label={`Low ${eventsSummaryData?.data?.severity?.Low?.count || '-'}`} severity="Low" />
                </Stack>
              </Stack>
            </Stack>
            <Box
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FBFAFC",
                padding: isRestructured ? "10px 24px" : "20px 24px",
              }}
            >
              {eventsSummaryData?.data?.status?.Active?.count === 0 &&
              eventsSummaryData?.data?.status?.Closed?.count === 0 ? (
                <Stack direction="column" gap={3} alignItems="center">
                  <Box sx={{ display: "flex" }}>
                    <img
                      style={{
                        width: isMobileScreen ? "21px" : "56px",
                        height: isMobileScreen ? "21px" : "56px",
                        objectFit: "contain",
                      }}
                      src={`${process.env.REACT_APP_CDN_BASE_URL}/images/success.png`}
                    />
                  </Box>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      color: "rgba(61, 61, 61, 1)",
                      fontWeight: typography.font_weight.bold,
                    })}
                  >
                    No errors found impacting your business
                  </Typography>
                </Stack>
              ) : (
                <Stack direction={isRestructured ? "row" : "column"} gap={3} alignItems="center">
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      color: "var(--greyish-lavender)",
                      fontWeight: typography.font_weight.mediumx,
                    })}
                  >
                    Total Estimated Impact
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(isRestructured ? 16 : 24),
                      color: "var(--charcoal-grey)",
                      fontWeight: typography.font_weight.bold,
                    })}
                  >
                    {(eventsSummaryData?.data?.severity?.High?.count === 0 &&
                      eventsSummaryData?.data?.severity?.Medium?.count > 0) ||
                    eventsSummaryData?.data?.impact === undefined
                      ? "-"
                      : eventsSummaryData?.data?.impact?.monetary?.formatted_value}
                  </Typography>
                  {(eventsSummaryData?.data?.severity?.High?.count === 0 &&
                    eventsSummaryData?.data?.severity?.Medium?.count > 0) ||
                  eventsSummaryData?.data?.impact === undefined ? (
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(12),
                        color: "var(--dark-grey)",
                        fontWeight: typography.font_weight.regular,
                        display: isRestructured ? "none" : "unset",
                      })}
                    >
                      We’re working diligently to quantify impact
                    </Typography>
                  ) : eventsSummaryData?.data?.impact?.monetary?.delta_percentage !== null ? (
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: "center",
                        display:
                          eventsSummaryData?.data?.impact?.monetary?.delta_percentage === null ||
                          isRestructured
                            ? "none"
                            : "flex",
                      }}
                    >
                      <StyledChip
                        sx={{
                          pl: 3,
                          background:
                            eventsSummaryData?.data?.impact?.monetary?.delta_percentage < 0
                              ? colors.green["150"]
                              : colors.red["150"],
                        }}
                        icon={
                          <Icon
                            name={
                              eventsSummaryData?.data?.impact?.monetary?.delta_percentage > 0
                                ? "arrow-negative-up"
                                : "arrow-positive-down"
                            }
                            size="8px"
                          />
                        }
                        label={
                          <Typography
                            sx={(theme) => ({
                              fontSize: theme.typography.pxToRem(12),
                              color:
                                eventsSummaryData?.data?.impact?.monetary?.delta_percentage < 0
                                  ? colors.SEVERITY.GREEN.medium
                                  : "var(--red-3)",
                            })}
                          >
                            {eventsSummaryData?.data?.impact?.monetary?.formatted_delta_percentage}
                          </Typography>
                        }
                      />
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(12),
                          color: "rgba(61, 61, 61, 1)",
                          ml: 1,
                        })}
                      >
                        from last {PIPELINE_SCHEDULE_TRANSLATION[pipelineSchedule]}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
              )}
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
              [theme.breakpoints.down("tm")]: {
                flexDirection: "column",
                gap: "12px",
              },
            })}
          >
            <Stack
              direction="row"
              gap={2}
              sx={(theme) => ({
                [theme.breakpoints.down("tm")]: {
                  flexWrap: "wrap",
                  width: "100%",
                },
              })}
            >
              <MultiSelect
                multiple={false}
                disableCloseOnSelect={false}
                placeholder="Select Severity"
                onChange={(event, newVal) => {
                  const value = newVal ? newVal?.value : null;
                  handleSummaryFilters(event, "severity", value);
                }}
                options={severityOptions}
                value={
                  summaryFilters.severity
                    ? severityOptions.find((item) => item.value === summaryFilters.severity)
                    : null
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value?.label}
                optionsCheckbox={false}
                renderInput={(params) => {
                  const selectedValue = severityOptions.find(
                    (item) => item?.value === summaryFilters?.severity
                  );
                  return (
                    <StyledInput
                      {...params}
                      ref={params.InputProps.ref}
                      placeholder="Select Severity"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment:
                          selectedValue && selectedValue.icon ? ( // Show the logo if the value exists
                            <Avatar
                              src={selectedValue.icon}
                              alt={selectedValue.label}
                              sx={{ width: "14px", height: "14px", objectFit: "contain" }}
                            />
                          ) : null,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                        readOnly: true, // Makes input non-editable
                        onKeyDown: (e) => e.preventDefault(), // Prevent keyboard input
                        onPaste: (e) => e.preventDefault(), // Prevent pasting
                        style: { caretColor: 'transparent' }
                      }}
                    />
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "12px"}}>
                    <Avatar
                      src={option.icon}
                      alt={option.label}
                      sx={{ width: "14px", height: "14px", mr: 1 }}
                    />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.label}
                      </span>
                  </li>
                )}
                sx={(theme) => ({
                  flexGrow: 1,
                  minWidth: "108px",
                  maxWidth: "146px",
                  borderRadius: "8px",
                  backgroundColor: "#FBFAFC",
                  "& .MuiAutocomplete-input": {
                    color: "var(--charcoal-grey)",
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    width: "unset !important",
                    cursor: "pointer",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.5px solid #EBEAED !important",
                    borderRadius: "8px !important",
                    boxShadow: "unset !important",
                  },
                  [theme.breakpoints.down("tm")]: {
                    width: "48%",
                    maxWidth: "48%",
                  },
                })}
              />
              <MultiSelect
                multiple={false}
                disableCloseOnSelect={false}
                placeholder="Select Status"
                onChange={(event, newVal) => {
                  const value = newVal ? newVal?.value : null;
                  handleSummaryFilters(event, "status", value);
                }}
                options={statusOptions}
                value={
                  summaryFilters.status
                    ? statusOptions.find((item) => item.value === summaryFilters.status)
                    : null
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value?.label}
                sx={(theme) => ({
                  flexGrow: 1,
                  minWidth: "108px",
                  maxWidth: "139px",
                  borderRadius: "8px",
                  backgroundColor: "#FBFAFC",
                  "& .MuiAutocomplete-input": {
                    color: "var(--charcoal-grey)",
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    cursor: "pointer",
                    width: "unset !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.5px solid #EBEAED !important",
                    borderRadius: "8px !important",
                    boxShadow: "unset !important",
                  },
                  [theme.breakpoints.down("tm")]: {
                    width: "48%",
                    maxWidth: "48%",
                  },
                })}
                renderInput={(params) => {
                  const selectedValue = statusOptions.find((item) => item.value === summaryFilters.status);
                  return (
                    <StyledInput
                      {...params}
                      ref={params.InputProps.ref}
                      placeholder="Select Status"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment:
                          selectedValue && selectedValue?.icon ? ( // Show the logo if the value exists
                            <Avatar
                              src={selectedValue?.icon}
                              alt={selectedValue.label}
                              sx={{ width: "14px", height: "14px", objectFit: "contain" }}
                            />
                          ) : null,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                        readOnly: true, // Makes input non-editable
                        onKeyDown: (e) => e.preventDefault(), // Prevent keyboard input
                        onPaste: (e) => e.preventDefault(), // Prevent pasting
                        style: { caretColor: 'transparent' }
                      }}
                    />
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "12px"}}>
                    <Avatar
                      src={option.icon}
                      alt={option.label}
                      sx={{ width: "14px", height: "14px", mr: 1 }}
                    />
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.label}
                      </span>
                  </li>
                )}
                optionsCheckbox={false}
              />
              <MultiSelect
                multiple={false}
                disableCloseOnSelect={false}
                placeholder="Select Type"
                onChange={(event, newVal) => {
                  const value = newVal ? newVal?.value : null;
                  handleSummaryFilters(event, "eventType", value);
                }}
                options={typeOptions}
                value={
                  summaryFilters.eventType
                    ? typeOptions.find((item) => item.value.type === summaryFilters.eventType.type && item.value.subType === summaryFilters.eventType.subType)
                    : null
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value?.label}
                sx={(theme) => ({
                  flexGrow: 1,
                  minWidth: "108px",
                  maxWidth: "150px",
                  borderRadius: "8px",
                  backgroundColor: "#FBFAFC",
                  "& .MuiAutocomplete-input": {
                    color: "var(--charcoal-grey)",
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    cursor: "pointer",
                    width: "unset !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.5px solid #EBEAED !important",
                    borderRadius: "8px !important",
                    boxShadow: "unset !important",
                  },
                  [theme.breakpoints.down("tm")]: {
                    width: "48%",
                    maxWidth: "48%",
                  },
                })}
                optionsCheckbox={false}
                renderInput={(params) => {
                  return (
                    <StyledInput
                      {...params}
                      ref={params.InputProps.ref}
                      placeholder="Select Type"
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true, // Makes input non-editable
                        onKeyDown: (e) => e.preventDefault(), // Prevent keyboard input
                        onPaste: (e) => e.preventDefault(), // Prevent pasting
                        style: { caretColor: 'transparent' }
                      }}
                    />
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "12px"}}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.label}
                      </span>
                  </li>
                )}
              />
              <MultiSelect
                multiple={false}
                disableCloseOnSelect={false}
                onChange={(event, newVal) => {
                  const selectedProviderDetails = eventsSummaryData?.data?.top_providers.find(
                    (provider) => provider.provider_uuid === newVal?.provider_uuid
                  );
                  if (selectedProviderDetails) {
                    handleSummaryFilters(event, "provider_uuid", {
                      id: selectedProviderDetails.provider_uuid,
                      name: selectedProviderDetails.provider_display_name,
                    });
                  } else {
                    handleSummaryFilters(event, "provider_uuid", null);
                  }
                }}
                options={eventsSummaryData ? eventsSummaryData.data.top_providers : []}
                value={eventsSummaryData?.data?.top_providers?.length && summaryFilters.provider_uuid ? eventsSummaryData?.data?.top_providers.find(
                  (item) => item.provider_uuid === summaryFilters.provider_uuid?.id
                ): null}
                getOptionLabel={(option) => option.provider_display_name}
                isOptionEqualToValue={(option, value) =>
                  option.provider_uuid === value.provider_uuid
                }
                optionsCheckbox={false}
                enableEllipsis
                renderInput={(params) => {
                  const selectedValue = eventsSummaryData?.data?.top_providers.find(
                    (item) => item?.provider_uuid === summaryFilters?.provider_uuid?.id
                  );
                  return (
                    <StyledInput
                      {...params}
                      ref={params.InputProps.ref}
                      placeholder="Select Provider"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment:
                          selectedValue && selectedValue.provider_logo_url ? ( // Show the logo if the value exists
                            <Avatar
                              src={selectedValue.provider_logo_url}
                              alt={selectedValue.provider_display_name}
                              sx={{ width: "16px", height: "16px", mr: 1 }}
                            />
                          ) : null,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                        readOnly: true, // Makes input non-editable
                        onKeyDown: (e) => e.preventDefault(), // Prevent keyboard input
                        onPaste: (e) => e.preventDefault(), // Prevent pasting
                        style: { caretColor: 'transparent' }
                      }}
                    />
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "12px"}}>
                    <Avatar
                      src={option.provider_logo_url}
                      alt={option.provider_display_name}
                      sx={{ width: "16px", height: "16px", mr: 1 }}
                    />
                    <Tooltip title={option.provider_display_name} placement="top">
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.provider_display_name}
                      </span>
                    </Tooltip>
                  </li>
                )}
                sx={(theme) => ({
                  flexGrow: 1,
                  minWidth: "108px",
                  maxWidth: "unset",
                  borderRadius: "8px",
                  backgroundColor: "#FBFAFC",
                  "& .MuiAutocomplete-input": {
                    color: "var(--charcoal-grey)",
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.regular,
                    cursor: "pointer",
                    width: "unset !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.5px solid #EBEAED !important",
                    borderRadius: "8px !important",
                    boxShadow: "unset !important",
                  },
                  [theme.breakpoints.down("tm")]: {
                    maxWidth: "48%",
                    width: "48%",
                  },
                })}
              />
              {summaryFilters && !isEmpty(summaryFilters) ? (
                <Button
                  variant="text"
                  sx={(theme) => ({
                    textDecoration: "underline",
                    fontSize: theme.typography.pxToRem(11),
                    [theme.breakpoints.down("tm")]: {
                      width: "100%",
                    },
                  })}
                  onClick={() => handleClearFilters()}
                >
                  Clear All
                </Button>
              ) : null}
            </Stack>
            <EventFeedTimeFilter />
          </Stack>
          {/* <Stack alignItems="flex-end">
          {showLastProcessedData()}
          </Stack> */}
        </Stack>
      </EventSummaryCard>
    </>
  );
};
export default EventSummary;
