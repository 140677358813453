import styled from "@emotion/styled";
import {
  Box,
  CardActionArea,
  Chip,
  Divider,
  Popover,
  Stack,
  Tooltip,
  Typography,
  listClasses,
  popoverClasses,
} from "@mui/material";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BROWSER_EVENT_SUB_TYPES,
  EVENT_SUB_TYPE_BEACON,
  EVENT_SUB_TYPE_EXPERIRENCE,
  EVENT_SUB_TYPE_JAVASCRIPT,
  EVENT_SUB_TYPE_NETWORK,
  EVENT_TYPE_ERROR,
  EVENT_TYPE_OUTAGE,
  EVENT_SUB_TYPE_ASSET
} from "../../../constants/eventConstants";
import { colors, typography } from "../../../shared/theme-constants";
import { formatDateForTimeChip } from "../../../utils/dateUtils";
import { isNullOrEmpty } from "../../../utils/is";
import { titleCase } from "../../../utils/stringUtils";
import Icon from "../../common/Icon";
import EventSeverityLabels from "./EventSeverityLabels";
import EventStatusLabels from "./EventStatusLabels";
import EventTypeLabel from "./EventTypeLabels";
import { INACTIVE_STATUSES } from "../../../constants/commonConstants";

const StyledChip = styled(Chip)(({ theme, padding }) => ({
  minWidth: theme.typography.pxToRem(70),
  height: theme.typography.pxToRem(30),
  borderRadius: theme.typography.pxToRem(6),
  "&& .MuiChip-label": {
    paddingLeft: padding ? `${padding} !important` : "0rem !important",
    paddingRight: padding ? `${padding} !important` : "0rem !important",
    fontFamily: "Poppins !important",
    fontSize: "11px",
    fontWeight: 400,
  },
  "&& .MuiChip-icon": {
    margin: 0,
    marginRight: "4px",
  },
}));

const StyledPopover = styled(Popover)({
  pointerEvents: "none",

  [`& .${popoverClasses.paper}`]: {
    width: "100%",
    maxWidth: "15rem",
    maxHeight: "18rem",
    padding: "0.5rem 1rem",
    overflow: "hidden", // Ensure no overflow

    [`& .${listClasses.root}`]: {
      maxHeight: "14rem",
      overflowY: "auto",
    },
  },
});

const generateOutageTitle = (providerDisplayName, severity) => {
  switch (severity) {
    case "High":
      return `${providerDisplayName} Major Incident`;
    case "Medium":
      return `${providerDisplayName} Partial Disruption`;
    case "Low":
      return `${providerDisplayName} Scheduled Maintenance`;
    default:
      return `${providerDisplayName} Incident`;
  }
};

function EventGroupCardHeader({
  providerLogo,
  eventStatus,
  eventCategory,
  categoryLogo,
  type,
  sub_type,
  numberOfEvents,
  providerDisplayName,
  description,
  impact,
  kpis,
  event_group_id,
  isDescriptionSectionEnabled = true,
  severity,
  source = "listing",
  funnels = [],
  affected_components,
  eventSeqId,
  eventStartTime,
  eventEndTime,
  timezone,
  duration,
  title,
  summary,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // function to hanlde hover  condition for list of metrics
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // function to hanlde hover away condition for list of metrics
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getEventTitle = () => {
    if (type === EVENT_TYPE_OUTAGE) {
      return generateOutageTitle(providerDisplayName, severity);
    } else if (type === EVENT_TYPE_ERROR) {
      switch (sub_type) {
        // Added repeatitive code as we wanted to be super safe
        case EVENT_SUB_TYPE_BEACON:
          return title;
        case EVENT_SUB_TYPE_JAVASCRIPT:
          return title;
        case EVENT_SUB_TYPE_NETWORK:
          return title;
        case EVENT_SUB_TYPE_EXPERIRENCE:
          return title;
        case EVENT_SUB_TYPE_ASSET:
          return title;
        default:
          return `${numberOfEvents || ""} ${eventCategory} ${pluralize(
            titleCase(type || ""),
            numberOfEvents
          )}`;
      }
    }
  };

  const getEventSummary = () => {
    if (type === EVENT_TYPE_OUTAGE) {
      if (affected_components?.length > 0) {
        return (
          <span>
            <span style={{ fontWeight: 450 }}>Affected Components:</span>{" "}
            {affected_components?.slice(0, 3).join(", ")}
          </span>
        );
      } else {
        return "";
      }
    } else if ((type = EVENT_TYPE_ERROR)) {
      switch (sub_type) {
        // Added repeatitive code as we wanted to be super safe
        case EVENT_SUB_TYPE_BEACON:
          return summary;
        case EVENT_SUB_TYPE_JAVASCRIPT:
          return summary;
        case EVENT_SUB_TYPE_NETWORK:
          return summary;
        case EVENT_SUB_TYPE_EXPERIRENCE:
          return summary;
        case EVENT_SUB_TYPE_ASSET:
          return summary;
        default:
          return description;
      }
    }
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <CardActionArea
        disableRipple
        sx={(theme) => ({
          font: "inherit",
          // height: "210px",
          [theme.breakpoints.down("lt")]: { height: "auto" },
          cursor: source === "view" ? "default" : "pointer",
          "& .MuiCardActionArea-focusHighlight": { display: "none" },
        })}
        onClick={() =>
          navigate(`/events/groups/${event_group_id}`, {
            state: {
              isOverlay: true,
              backgroundLocation: location,
            },
          })
        }
      >
        <Box
          style={{ display: "flex", flexDirection: "column", gap: 24 }}
          sx={(theme) => ({
            overflowX: "hidden",
            width: "100%",
            [theme.breakpoints.down("lt")]: { flexDirection: "column" }, // Stack vertically on mobile and tablet.
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
              width: "100%",
              [theme.breakpoints.down("tm")]: {
                flexDirection: "column",
                gap: "12px",
              },
            })}
          >
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              width="55%"
              sx={(theme) => ({
                [theme.breakpoints.down("tm")]: {
                  width: "100%",
                },
              })}
            >
              <Tooltip title={providerDisplayName} placement="bottom-end">
                <img
                  style={{ width: "22px", height: "22px", objectFit: "contain" }}
                  src={providerLogo}
                />
              </Tooltip>
              <Tooltip
                title={getEventTitle()}
                placement="bottom-end"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxHeight: "auto", // Allows the tooltip to expand vertically as needed
                      maxWidth: "50vw", // Expands the tooltip horizontally based on screen width
                      width: "100%", // Sets width to 100%
                      overflow: "hidden",
                    },
                  },
                }}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: typography.font_size["xl"],
                    [theme.breakpoints.down("sl")]: {
                      fontSize: typography.font_size.base,
                    },
                    fontWeight: typography.font_weight.mediumx,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  })}
                >
                  {getEventTitle()}
                </Typography>
              </Tooltip>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              sx={(theme) => ({
                [theme.breakpoints.down("tm")]: {
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  rowGap: "8px",
                },
              })}
            >
              {eventStatus && !isNullOrEmpty(eventStatus) ? (
                <EventStatusLabels
                  status={
                    eventStatus === "Active" ? "live" : eventStatus === "Closed" ? "closed" : null
                  }
                />
              ) : null}

              {severity ? <EventSeverityLabels label={severity} severity={severity} /> : null}

              <EventTypeLabel label={titleCase(type)} />

              <EventTypeLabel label="Site Monitoring" />
              {Array.isArray(funnels) && funnels?.length > 0 && (
                <EventTypeLabel label={funnels[0]} />
              )}
            </Stack>
          </Stack>
          {isDescriptionSectionEnabled && (
            <Box
              sx={(theme) => ({
                width: "70%",
                [theme.breakpoints.down("tm")]: {
                  width: "100%",
                },
              })}
            >
              <Tooltip
                title={
                  type === EVENT_TYPE_OUTAGE
                    ? affected_components?.join(", ") || ""
                    : type === EVENT_TYPE_ERROR && BROWSER_EVENT_SUB_TYPES.includes(sub_type)
                    ? getEventSummary()
                    : description
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxHeight: "auto", // Allows the tooltip to expand vertically as needed
                      maxWidth: "50vw", // Expands the tooltip horizontally based on screen width
                      width: "100%", // Sets width to 100%
                      overflow: "hidden",
                    },
                  },
                }}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    color: "var(--greyish-lavender)",
                    [theme.breakpoints.down("tm")]: {
                      fontSize: theme.typography.pxToRem(10),
                    },
                    fontWeight: 300,
                    width: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  })}
                >
                  {getEventSummary()}
                </Typography>
              </Tooltip>
            </Box>
          )}
          <Stack
            direction="row"
            sx={(theme) => ({
              width: "100%",
              flexWrap: "wrap",
              [theme.breakpoints.down("tm")]: {
                flexWrap: "wrap",
                gap: "0px",
                columnGap: "70px",
                rowGap: "15px",
              },
            })}
            gap={25.75}
          >
            <Stack direction="row" gap={3}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={(theme) => ({
                  [theme.breakpoints.down("lt")]: {
                    display: "none",
                  },
                })}
              />
              <Stack direction="column" gap={2.5} sx={(theme => ({
                [theme.breakpoints.down("tm")]: { gap: 0 }
              }))}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--greyish-lavender)",
                  })}
                >
                  Event Id
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dark-grey)",
                  })}
                >
                  {eventSeqId}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={3}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={(theme) => ({
                  [theme.breakpoints.down("lt")]: {
                    display: "none",
                  },
                })}
              />
              <Stack direction="column" gap={2.5} sx={(theme => ({
                [theme.breakpoints.down("tm")]: { gap: 0 }
              }))}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--greyish-lavender)",
                  })}
                >
                  Start of Event
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dark-grey)",
                  })}
                >
                  {formatDateForTimeChip(eventStartTime, null, timezone, "ddd, DD MMM, hh:mm A z")}
                </Typography>
              </Stack>
            </Stack>
            {source === "view" && INACTIVE_STATUSES.includes(eventStatus) ? (
              <Stack direction="row" gap={3}>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={(theme) => ({
                    [theme.breakpoints.down("lt")]: {
                      display: "none",
                    },
                  })}
                />
                <Stack direction="column" gap={2.5} sx={(theme => ({
                [theme.breakpoints.down("tm")]: { gap: 0 }
              }))}>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.mediumx,
                      color: "var(--greyish-lavender)",
                    })}
                  >
                    End of Event
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.mediumx,
                      color: "var(--dark-grey)",
                    })}
                  >
                    {formatDateForTimeChip(eventEndTime, null, timezone, "ddd, DD MMM, hh:mm A z")}
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
            <Stack direction="row" gap={3}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={(theme) => ({
                  [theme.breakpoints.down("lt")]: {
                    display: "none",
                  },
                })}
              />
              <Stack direction="column" gap={2.5} sx={(theme => ({
                [theme.breakpoints.down("tm")]: { gap: 0 }
              }))}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--greyish-lavender)",
                  })}
                >
                  Duration
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--dark-grey)",
                  })}
                >
                  {duration}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={3}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={(theme) => ({
                  [theme.breakpoints.down("lt")]: {
                    display: "none",
                  },
                })}
              />
              <Stack direction="column" gap={2.5} sx={(theme => ({
                [theme.breakpoints.down("tm")]: { gap: 0 }
              }))}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: typography.font_weight.mediumx,
                    color: "var(--greyish-lavender)",
                  })}
                >
                  Dollar Impact
                </Typography>
                <Typography
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.mediumx,
                    color:
                      impact?.monetary?.value > 0
                        ? colors.SEVERITY.GREEN.medium
                        : !impact?.monetary
                        ? "var(--greyish-lavender)"
                        : "var(--red-3)",
                  })}
                >
                  {impact?.monetary ? (
                    <>
                      <Icon
                        name={
                          impact?.monetary?.value > 0 ? "arrow-positive-up" : "arrow-negative-down"
                        }
                        size="12px"
                      />
                      {impact?.monetary?.formatted_value}
                    </>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Stack>
            {/* The below code will be required in future. Decision regarding it is yet to be made.
                            {impact?.rate && (
                  <Stack direction="row" gap={1} alignItems="center">
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={`/images/${impact?.monetary?.value > 0
                        ? "stop-watch-positive.png"
                        : "stop-watch-negative.png"
                        }`}
                    />
                    <Typography
                      sx={(theme) => ({
                        [theme.breakpoints.down("lt")]: {
                          fontSize: theme.typography.pxToRem(12),
                        },
                        fontSize: theme.typography.pxToRem(15),
                        fontWeight: typography.font_weight.regular,
                        color:
                          impact?.monetary?.value > 0
                            ? colors.SEVERITY.GREEN.medium
                            : "var(--red-3)",
                      })}
                    >
                      {impact.rate.formatted_value}
                    </Typography>
                  </Stack>
                )} */}
          </Stack>
        </Box>
      </CardActionArea>
    </>
  );
}

export default EventGroupCardHeader;
