import React, { useEffect } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_TIMEZONE, EVENTS } from "../../../constants/commonConstants";
import { SET_COLLABORATE_TIMESTAMP } from "../../../constants/filterCriteriaConstants";
import { KPIS, METRIC_COMPARE, RESET_COLLABORATE_FILTERS } from "../../../constants/multiSelectFilterConstants";
import useBoolean from "../../../hooks/useBoolean";
import { calculateOverlayDuration } from "../../../shared/highcharts-config/timeseries-overlay-config";
import { colors, typography } from "../../../shared/theme-constants";
import CompareMetrics from "../../collaborate/CompareMetrics";
import FilterStack from "../../collaborate/Filter/FilterStack";
import TimePickerModal from "../../common/TimePickerModal";
import DimensionsBreakup from "../../collaborate/CompareMetrics/DimensionsBreakup";
const moment = require('moment-timezone');

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(4)}` },
  borderRadius: 12,
  overflow: "unset",
  boxShadow: "0px 2px 4px 0px #00000014",
  backgroundColor: colors.white,
  position: "relative",
}));

function EventsTimelineChart({
  tenantId,
  pipelineSchedule,
  kpiId,
  eventSubType,
  endTime,
  startTime,
  kpiName,
  kpiDisplayName,
  event_details_message, // Mandatory only in case of errors as this is the uniquesness criteria for aggregation
  eventGroupStart,
  eventGroupEnd,
  isOverlayRequired = false,
  metric = {},
}) {
  const dispatch = useDispatch();
  const  { chartType } = useSelector((state)=> state.collaborateFilters);

  const globalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);
  const [isTimeFilterOpen, { setTrue: openTimeFilterModal, setFalse: closeTimeFilterModal }] =
    useBoolean();
      // Get the tenant configs
  const tenantConfigs = useSelector((state) => state.tenantConfigs.configs);

  // Get the timezone from the tenant configs
  let timezone = tenantConfigs?.timezone?.name ? tenantConfigs.timezone.name : DEFAULT_TIMEZONE;

  useEffect(() => {
    const arrayWithTopMetric = [
      {
        kpi_id: kpiId,
        kpi_display_name: kpiDisplayName,
        kpi_name: kpiName,
      },
    ];
    dispatch({ type: KPIS, payload: arrayWithTopMetric });
    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: {
        start_time: startTime,
        end_time: endTime,
      },
    });
    // Return a cleanup function for component unmounting
    return () => {
      // Any necessary cleanup logic goes here
      dispatch({ type: RESET_COLLABORATE_FILTERS });
    };
  }, [dispatch, kpiId, startTime, endTime, kpiName, kpiDisplayName]);

  const handleTimeSelected = (selectedStartEndTimestamp) => {
    // For last hour we want to show user the default start time and end time which initially loads.
    if (selectedStartEndTimestamp && selectedStartEndTimestamp.type === "preset/last_hour") {
      const defaultTimeStamp = { ...selectedStartEndTimestamp, start_time: startTime, end_time: endTime };
      dispatch({
        type: SET_COLLABORATE_TIMESTAMP,
        payload: defaultTimeStamp,
      });
    } else {
      const startDateStrWithoutZ = selectedStartEndTimestamp?.start_time.replace('Z', '');
      const updatedStartDateFormat = moment.tz(startDateStrWithoutZ, timezone);
      const startdateInUTC = updatedStartDateFormat.utc().format();

      const endDateStrWithoutZ = selectedStartEndTimestamp?.end_time.replace('Z', '');
      const updatedEndDateFormat = moment.tz(endDateStrWithoutZ, timezone);
      const enddateInUTC = updatedEndDateFormat.utc().format();

      const updateDateObj = {
        ...selectedStartEndTimestamp,
        start_time: startdateInUTC,
        end_time: enddateInUTC,
      };
      dispatch({
        type: SET_COLLABORATE_TIMESTAMP,
        payload: updateDateObj,
      });
    }
  };

  const plotBands = [];

  if (eventGroupStart && eventGroupEnd && isOverlayRequired) {

  // Convert the date to a specific timezone (e.g., 'America/Los_Angeles' for PST)
  const groupStartDateEpoch = moment.tz(eventGroupStart, timezone  === null ?  DEFAULT_TIMEZONE : timezone).valueOf();
  const groupEndDateEpoch = moment.tz(eventGroupEnd, timezone  === null ?  DEFAULT_TIMEZONE : timezone).valueOf();

  // Get the epoch time in milliseconds
  const overlayDuration = calculateOverlayDuration('hourly');
  const overlayStartDateEpoch = groupStartDateEpoch - overlayDuration;
  const overlayEndDateEpoch = groupEndDateEpoch + overlayDuration;

  plotBands.push({
    color: 'rgba(229, 95, 95, 0.25)',
    from: overlayStartDateEpoch,
    to: overlayEndDateEpoch,
  });
}

return (
    <StyledCard>
      {isTimeFilterOpen && (
        <TimePickerModal onClose={closeTimeFilterModal} onSelect={handleTimeSelected} />
      )}
      <Stack direction="column" justifyContent="space-between" sx={{ mb: 6 }}>
        <CardHeader
          title="Timeline of Events"
          sx={{
            padding: "16px 6px",
          }}
          titleTypographyProps={{
            sx: {
              fontSize: 18,
              fontWeight: typography.font_weight.semiBold,
              color: "var(--charcoal-grey)",
            },
          }}
        />
        <FilterStack
          onTimeFilterClick={openTimeFilterModal}
          tenantId={tenantId}
          kpiId={kpiId}
          pipelineSchedule={pipelineSchedule}
          chartType={chartType}
          globalFilter={globalFilter}
          pageType={EVENTS}
          metric={metric}
        />
      </Stack>
      <CardContent sx={{ padding: 0 }}>
        {chartType === METRIC_COMPARE ? (
        <CompareMetrics
          tenantId={tenantId}
          kpiId={kpiId}
          pipelineSchedule={pipelineSchedule}
          subType={eventSubType}
          isTitleRequired={false}
          event_details_message={event_details_message}
          plotBands={plotBands}
          isForecastEnabled={false}
          timezone={timezone}
          isTimeRequired
        />
      ) : (
        <DimensionsBreakup
        metric={metric}
        chartType={chartType}
        kpiId={kpiId}
        pipelineSchedule={pipelineSchedule}
        subType={eventSubType}
        pageType={EVENTS}
        isTimeRequired
        timezone={timezone}
      />
      )}
      </CardContent>
    </StyledCard>
  );
}

export default EventsTimelineChart;
